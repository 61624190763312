import React from 'react';
import { IntlProvider } from 'react-intl';
import './App.scss';

import './Countdown.js'
import Countdown from './Countdown.js';

const usersLocale = 'en';
const translationsForUsersLocale = {
    'title': '',
    'days': 'tage',
    'hours': 'stunden',
    'minutes': 'minuten',
    'seconds': 'sekunden'
}

function App(props) {
  const { dDayDate, timezoneOffset, variant, title, labels } = props;

  const messages = labels ? labels : translationsForUsersLocale;

  return (
    <IntlProvider locale={usersLocale} messages={messages}>
      <Countdown
        title={title || 'title'}
        dDayDate={dDayDate}
        timezoneOffset={timezoneOffset}
        light={ variant === 'light' }
        dark={ variant === 'dark' }
      />
    </IntlProvider>
  );
}

export default App;
