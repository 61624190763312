// @flow
import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import BrandLogo from '../src/icons/metro-obd-logo.svg';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const DATE_OBJECT = new Date();

function padNumber(number) {
  const stringNumber = String(number);
  if (String(stringNumber).length === 1) {
    return `0${stringNumber}`;
  }
  return stringNumber;
}

class Countdown extends Component {

  static defaultProps = {
    timezoneOffset: -DATE_OBJECT.getTimezoneOffset() / 60,
  };

  constructor(props) {
    super(props);
    this.state = {
      timeLeft: this.setTimeLeft(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        timeLeft: prevState.timeLeft - SECOND,
      }));
      const secondsLeft = this.state.timeLeft / SECOND;
      if (secondsLeft < 0) {
        this.stop();
        this.setState({ timeLeft: 0 });
        if (secondsLeft > -3) {
          window.location.reload(false);
        }
      }
    }, SECOND);
    this.checkInterval = setInterval(
      () =>
        this.setState(() => ({
          timeLeft: this.setTimeLeft(),
        })),
      MINUTE,
    );
  }

  componentWillUnmount() {
    this.stop();
  }

  stop() {
    clearInterval(this.interval);
    clearInterval(this.checkInterval);
  }

  setTimeLeft() {
    const { currentLocale, dDayDate, isDday, timezoneOffset } = this.props;
    let time = new Date(dDayDate) - new Date() - timezoneOffset * HOUR;
    if (currentLocale && currentLocale === 'en-nn') {
      time = new Date(dDayDate) - new Date() + new Date().getTimezoneOffset() * 60 * 1000;
    }

    if (isDday) {
      time += DAY;
    }
    return time;
  }

  getValues() {
    const { timeLeft } = this.state;
    const days = Math.floor(timeLeft / DAY);
    const hours = Math.floor((timeLeft - days * DAY) / HOUR);
    const minutes = Math.floor((timeLeft - days * DAY - hours * HOUR) / MINUTE);
    const seconds = Math.floor((timeLeft - days * DAY - hours * HOUR - minutes * MINUTE) / SECOND);

    return {
      days: padNumber(days),
      hours: padNumber(hours),
      minutes: padNumber(minutes),
      seconds: padNumber(seconds),
    };
  }

  render() {
    const { title, dark, light } = this.props;
    const values = this.getValues();
    const counter = ['hours', 'minutes', 'seconds'];
    if (parseInt(values.days, 10) > 0) counter.unshift('days');

    return (
      <div
        className={classNames('countdown-wrapper', {
          'countdown-wrapper__light': light,
          'countdown-wrapper__dark': dark,
        })}
      >
        <div className="countdown-block">
          <div className="brand-logo">
            <img alt="" src={BrandLogo} />
          </div>

          <div className="countdown countdown-timer">
            {counter.map(part => (
              <div key={part} className={`part countdown-timer--${part}`}>
                <span className="value">{values[part]}</span>
                <span className="unit">
                  <FormattedMessage id={part} />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Countdown);
