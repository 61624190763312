import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const mountpoint = document.getElementById('mount-obd-countdown');
const props = {
    dDayDate: mountpoint.dataset.dueDate,
    timezoneOffset: mountpoint.dataset.timezoneOffset,
    variant: mountpoint.dataset.variant,

    title: mountpoint.dataset.title,
    labels: mountpoint.dataset.labels
}

ReactDOM.render( <App {...props} />, mountpoint);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
